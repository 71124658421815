import {
  CarOutlined,
  ContactsOutlined,
  ControlOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  FileDoneOutlined,
  GoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  PullRequestOutlined,
  ReconciliationOutlined,
  RetweetOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SketchOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import {
  AGENT,
  AGENT_COLLECTION,
  AGENT_GROUP,
  AGENT_INCENTIVES,
  AGENT_MANAGEMENT,
  AGENTS_PERFORMANCE,
  AUTO_INVENTORY_REPLENISHMENT,
  AUTOMATIC_PARTIAL_FULFILLMENT,
  AUTOMATIC_PROCUREMENT_CONFIG,
  BIDDING_INVITATION_LIST,
  BIDDING_REQUEST,
  BRAND_PORTAL,
  BUY_IN_INVOICE,
  CATEGORY_INSIGHTS,
  CLAIMS,
  CLAIMS_INVOICE,
  CLAIMS_MANAGEMENT,
  CONFIG_CM3_TARGET,
  CONSOLIDATED_PURCHASE_REQUEST,
  COVERAGE_REPORT,
  CREDIT,
  CREDIT_DEBIT_NOTE,
  DELIVERY_MANAGEMENT,
  DELIVERY_MANAGEMENT_HUB,
  DELIVERY_PERFORMANCE,
  DISTRIBUTOR_CENTER,
  DISTRIBUTOR_CONTRACT_FEE,
  DISTRIBUTOR_LIST,
  DISTRIBUTOR_MANAGEMENT,
  DISTRIBUTOR_MARGIN,
  DISTRIBUTOR_PROFILE,
  DISTRIBUTOR_PURCHASE_ORDER,
  DISTRIBUTOR_PURCHASE_REQUEST,
  DISTRIBUTOR_REGISTER,
  FAILED_DELIVERY_RECEIVE,
  FINANCE,
  FINANCE_TASK_LIST,
  FULFILLMENTS,
  GOODS_NOT_FOCUS,
  HOME,
  INBOUND_LIST,
  INCENTIVES_CONFIG,
  INVENTORY_ADJUSTMENT,
  INVENTORY_ALLOCATION,
  INVENTORY_DISTRIBUTOR_AGENT,
  INVENTORY_LIST,
  INVENTORY_RECOMMEND,
  INVENTORY_REPORT,
  LOGISTICS,
  MARKETING,
  MARKETING_BUDGET,
  MASTER_DATA,
  MENU_PRODUCT,
  MISSION_PERFORMANCE,
  NOTIFICATION,
  ORDER_LIST,
  OUTLET_MANAGEMENT,
  OUTLETS_PERFORMANCE,
  PACKAGE_LIST,
  PARTNER,
  PAYMENT_REQUEST,
  PAYOUT_STATEMENT,
  PICKLIST_ASSIGNMENT,
  PICKLIST_CHECKOUT,
  PRE_ORDERS,
  PRICE_MANAGEMENT,
  PRICE_TYPE_CONFIG,
  PROCUREMENT,
  PROCUREMENT_CONFIG,
  PRODUCT_AVAILABLE,
  PRODUCT_LIST,
  PRODUCT_LIST_VN,
  PRODUCT_LISTING,
  PRODUCT_PRE_ORDER,
  PRODUCT_PRE_ORDER_INDIA,
  PRODUCT_TRADING_BY_LISTING,
  PURCHASE_ORDER_LIST,
  PURCHASING,
  REASON_CANCEL,
  REASON_NEED_ADJUST,
  REMITTANCE_LIST,
  REPORT,
  REPORT_CLAIM,
  REPORT_CLAIMS,
  REPORT_CLAIMS_RETURN,
  REPORT_DISTRIBUTOR_RECONCILIATION,
  REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY,
  REPORT_GST_PURCHASE_REGISTER,
  REPORT_GST_PURCHASES,
  REPORT_GST_SALES,
  REPORT_GST_SALES_ADMIN,
  REPORT_GST_SALES_HUB,
  REPORT_GST_SALES_REGISTER_ADMIN,
  REPORT_GST_SALES_REGISTER_DETAILED_HUB,
  REPORT_GST_SALES_REGISTER_HUB,
  REPORT_GST_SALES_RETURN,
  REPORT_GST_SALES_RETURN_ADMIN,
  REPORT_GST_SALES_RETURN_HUB,
  REPORT_GST_SUMMARY_BY_HSN,
  REPORT_GSTR_SALES_REGISTER,
  REPORT_INVENTORY,
  REPORT_INVENTORY_SNAPSHOT,
  REPORT_INVOICE_LOADING,
  REPORT_PRODUCT_SUMMARY,
  REPORT_PURCHASE_RECONCILIATION,
  REPORT_PURCHASE_REGISTER_DETAILED,
  REPORT_PURCHASE_RETURN,
  REPORT_RIDER,
  REPORT_SALES_SUMMARY,
  REPORT_SALES_SUMMARY_OUTLET,
  REPORT_SALES_SUMMARY_SKU,
  REPORT_SUMMARY,
  RETAIL_PURCHASE_REQUEST_LIST,
  RETURN,
  RETURN_ORDER_LIST,
  RIDER,
  RIDER_MANAGEMENT,
  SALES_PERFORMANCE,
  SELL_OUT_INVOICE,
  SETTINGS,
  STOCK_TRANSFER,
  SUBSIDY_RULE,
  SUPPLIER_INVOICE_PRODUCT_MANAGEMENT,
  SUPPLIER_LIST,
  SUPPLIER_LIST_VN,
  SUPPLIER_PORTAL,
  SUPPLIER_PRODUCT_LIST,
  SUPPLIER_PRODUCT_LIST_VN,
  TASK_LIST,
  TAX_MANAGEMENT,
  TRADE_ORDERS,
  TRANSPORTATION_VENDOR,
  USER_LIST,
  VEHICLE_MANAGEMENT,
  VIGO_DELIVERY,
  VIGO_MASTER_QUOTATION,
  VIGO_PURCHASE_ORDER,
  WAREHOUSE_LIST,
  WAREHOUSE_WMS_LIST,
  WMS,
} from 'common/config/routes'
import { isIndia } from 'utils/commonUtils'
import {
  CREATE,
  CREATE_NEW,
  CREATE_RETAIL_PO,
  DETAIL,
  DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB,
  EDIT,
  PAYMENT_REQUEST_BREADCRUMB,
  PURCHASE_REQUEST,
  SA_PAYOUT_STATEMENT_DETAIl,
  SUBMIT_BIDDING,
  UPDATE,
  UPDATE_WAREHOUSE_DETAILS,
  VIGO_QUOTATION,
} from 'utils/localUtils'

export interface INavigation {
  name?: string
  href?: string
  key: string
  icon?: unknown
  display?: boolean // display on menu
  children?: INavigation[]
}

const settingChildren = [
  {
    name: USER_LIST,
    href: USER_LIST,
    key: USER_LIST,
    display: true,
  },
  {
    name: TASK_LIST,
    href: TASK_LIST,
    key: TASK_LIST,
    display: true,
  },
  {
    name: PROCUREMENT_CONFIG,
    href: PROCUREMENT_CONFIG,
    key: PROCUREMENT_CONFIG,
    display: !isIndia,
  },
]

const masterDataChildren = [
  {
    name: DISTRIBUTOR_LIST,
    href: DISTRIBUTOR_LIST,
    key: DISTRIBUTOR_LIST,
    display: true,
  },
  {
    name: DISTRIBUTOR_MARGIN,
    href: DISTRIBUTOR_MARGIN,
    key: DISTRIBUTOR_MARGIN,
    display: true,
  },
  {
    name: PRODUCT_LIST,
    href: PRODUCT_LIST,
    key: PRODUCT_LIST,
    display: true,
  },
  {
    name: PRODUCT_PRE_ORDER_INDIA,
    href: PRODUCT_PRE_ORDER_INDIA,
    key: PRODUCT_PRE_ORDER_INDIA,
    display: true,
    children: [
      {
        name: CREATE,
        href: `${PRODUCT_PRE_ORDER_INDIA}/create`,
        key: `${PRODUCT_PRE_ORDER_INDIA}/create`,
        display: false,
      },
      {
        name: UPDATE,
        href: `${PRODUCT_PRE_ORDER_INDIA}/[id]`,
        key: `${PRODUCT_PRE_ORDER_INDIA}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: SUPPLIER_LIST,
    href: SUPPLIER_LIST,
    key: SUPPLIER_LIST,
    display: true,
  },
  {
    name: SUPPLIER_PRODUCT_LIST,
    href: SUPPLIER_PRODUCT_LIST,
    key: SUPPLIER_PRODUCT_LIST,
    display: true,
  },
  {
    name: WAREHOUSE_LIST,
    href: WAREHOUSE_LIST,
    key: WAREHOUSE_LIST,
    display: true,
  },
]

const productChildren = [
  {
    name: PRODUCT_LIST_VN,
    href: PRODUCT_LIST_VN,
    key: PRODUCT_LIST_VN,
    display: isIndia,
  },
  {
    name: PRODUCT_AVAILABLE,
    href: PRODUCT_AVAILABLE,
    key: PRODUCT_AVAILABLE,
    display: !isIndia,
    children: [
      {
        name: CREATE,
        href: `${PRODUCT_LISTING}/create`,
        key: `${PRODUCT_LISTING}/create`,
        display: false,
      },
    ],
  },
  {
    name: PRODUCT_LISTING,
    href: PRODUCT_LISTING,
    key: PRODUCT_LISTING,
    display: !isIndia,
    children: [
      {
        name: DETAIL,
        href: `${PRODUCT_LISTING}/[id]`,
        key: `${PRODUCT_LISTING}/[id]`,
        display: false,
      },
      {
        name: CREATE,
        href: `${PRODUCT_LISTING}/create`,
        key: `${PRODUCT_LISTING}/create`,
        display: false,
      },
    ],
  },
  {
    name: PRODUCT_PRE_ORDER,
    href: PRODUCT_PRE_ORDER,
    key: PRODUCT_PRE_ORDER,
    display: true,
    children: [
      {
        name: CREATE,
        href: `${PRODUCT_PRE_ORDER}/create`,
        key: `${PRODUCT_PRE_ORDER}/create`,
        display: false,
      },
      {
        name: UPDATE,
        href: `${PRODUCT_PRE_ORDER}/[id]`,
        key: `${PRODUCT_PRE_ORDER}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: SUPPLIER_PRODUCT_LIST_VN,
    href: SUPPLIER_PRODUCT_LIST_VN,
    key: SUPPLIER_PRODUCT_LIST_VN,
    display: true,
  },
  {
    name: PRODUCT_TRADING_BY_LISTING,
    href: PRODUCT_TRADING_BY_LISTING,
    key: PRODUCT_TRADING_BY_LISTING,
    display: true,
    children: [
      {
        name: CREATE,
        href: `${PRODUCT_TRADING_BY_LISTING}/create`,
        key: `${PRODUCT_TRADING_BY_LISTING}/create`,
        display: false,
      },
      {
        name: EDIT,
        href: `${PRODUCT_TRADING_BY_LISTING}/[id]`,
        key: `${PRODUCT_TRADING_BY_LISTING}/[id]`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${PRODUCT_TRADING_BY_LISTING}/groups/[id]`,
        key: `${PRODUCT_TRADING_BY_LISTING}/groups/[id]`,
        display: false,
      },
    ],
  },
]

const partnerChildren = [
  {
    name: SUPPLIER_LIST_VN,
    href: SUPPLIER_LIST_VN,
    key: SUPPLIER_LIST_VN,
    display: true,
  },
]

const agentChildren = [
  {
    name: AGENT_MANAGEMENT,
    href: AGENT_MANAGEMENT,
    key: AGENT_MANAGEMENT,
    display: true,
  },
  {
    name: AGENT_COLLECTION,
    href: AGENT_COLLECTION,
    key: AGENT_COLLECTION,
    display: true,
  },
]

const supplierChildren = [
  //Purchase request India
  {
    name: RETAIL_PURCHASE_REQUEST_LIST,
    href: RETAIL_PURCHASE_REQUEST_LIST,
    key: RETAIL_PURCHASE_REQUEST_LIST,
    display: isIndia,
    children: [
      {
        name: CREATE,
        href: `${RETAIL_PURCHASE_REQUEST_LIST}/create`,
        key: `${RETAIL_PURCHASE_REQUEST_LIST}/create`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${RETAIL_PURCHASE_REQUEST_LIST}/[id]`,
        key: `${RETAIL_PURCHASE_REQUEST_LIST}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: PURCHASE_ORDER_LIST,
    href: PURCHASE_ORDER_LIST,
    key: PURCHASE_ORDER_LIST,
    display: true,
    children: [
      {
        name: CREATE_NEW,
        href: `${PURCHASE_ORDER_LIST}/create`,
        key: `${PURCHASE_ORDER_LIST}/create`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${PURCHASE_ORDER_LIST}/[id]`,
        key: `${PURCHASE_ORDER_LIST}/[id]`,
        display: false,
      },
      {
        name: CREATE_RETAIL_PO,
        href: `${PURCHASE_ORDER_LIST}/create-retail-po`,
        key: `${PURCHASE_ORDER_LIST}/create-retail-po`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${PURCHASE_ORDER_LIST}/retail-po/[id]`,
        key: `${PURCHASE_ORDER_LIST}/retail-po/[id]`,
        display: false,
      },
      {
        name: CREATE_NEW,
        href: `${PURCHASE_ORDER_LIST}/create-d2d-po`,
        key: `${PURCHASE_ORDER_LIST}/create-d2d-po`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${PURCHASE_ORDER_LIST}/d2d/[id]`,
        key: `${PURCHASE_ORDER_LIST}/d2d/[id]`,
        display: false,
      },
    ],
  },
  {
    name: BIDDING_REQUEST,
    href: BIDDING_REQUEST,
    key: BIDDING_REQUEST,
    display: !isIndia,
    children: [
      {
        name: CREATE_NEW,
        href: `${BIDDING_REQUEST}/create`,
        key: `${BIDDING_REQUEST}/create`,
        display: false,
      },
      {
        name: UPDATE,
        href: `${BIDDING_REQUEST}/[id]/update`,
        key: `${BIDDING_REQUEST}/[id]/update`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${BIDDING_REQUEST}/[id]`,
        key: `${BIDDING_REQUEST}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: BIDDING_INVITATION_LIST,
    href: BIDDING_INVITATION_LIST,
    key: BIDDING_INVITATION_LIST,
    display: !isIndia,
    children: [
      {
        name: SUBMIT_BIDDING,
        href: `${BIDDING_INVITATION_LIST}/submit-bidding/[id]`,
        key: `${BIDDING_INVITATION_LIST}/submit-bidding/[id]`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${BIDDING_INVITATION_LIST}/[id]`,
        key: `${BIDDING_INVITATION_LIST}/[id]`,
        display: false,
      },
    ],
  },
]

const brandChildren = [
  {
    name: SALES_PERFORMANCE,
    href: SALES_PERFORMANCE,
    key: SALES_PERFORMANCE,
    display: true,
  },
  {
    name: OUTLETS_PERFORMANCE,
    href: OUTLETS_PERFORMANCE,
    key: OUTLETS_PERFORMANCE,
    display: true,
  },
  {
    name: AGENTS_PERFORMANCE,
    href: AGENTS_PERFORMANCE,
    key: AGENTS_PERFORMANCE,
    display: true,
  },
  {
    name: CATEGORY_INSIGHTS,
    href: CATEGORY_INSIGHTS,
    key: CATEGORY_INSIGHTS,
    display: true,
  },
  {
    name: INVENTORY_REPORT,
    href: INVENTORY_REPORT,
    key: INVENTORY_REPORT,
    display: true,
  },
  {
    name: DELIVERY_PERFORMANCE,
    href: DELIVERY_PERFORMANCE,
    key: DELIVERY_PERFORMANCE,
    display: true,
  },
  {
    name: COVERAGE_REPORT,
    href: COVERAGE_REPORT,
    key: COVERAGE_REPORT,
    display: true,
  },
  {
    name: PRICE_MANAGEMENT,
    href: PRICE_MANAGEMENT,
    key: PRICE_MANAGEMENT,
    display: true,
  },
  {
    name: MISSION_PERFORMANCE,
    href: MISSION_PERFORMANCE,
    key: MISSION_PERFORMANCE,
    display: true,
  },
]

const claimsChildren = [
  {
    name: CLAIMS,
    href: CLAIMS,
    key: CLAIMS,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${CLAIMS}/[id]`,
        key: `${CLAIMS}/[id]`,
        display: false,
      },
      {
        name: UPDATE_WAREHOUSE_DETAILS,
        href: `${CLAIMS}/update-warehouse-details`,
        key: `${CLAIMS}/update-warehouse-details`,
        display: false,
      },
    ],
  },
  {
    name: CLAIMS_INVOICE,
    href: CLAIMS_INVOICE,
    key: CLAIMS_INVOICE,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${CLAIMS_INVOICE}/[id]`,
        key: `${CLAIMS_INVOICE}/[id]`,
        display: false,
      },
    ],
  },
]

const financeChildren = [
  {
    name: BUY_IN_INVOICE,
    href: BUY_IN_INVOICE,
    key: BUY_IN_INVOICE,
    display: !isIndia,
    children: [
      {
        name: DETAIL,
        href: `${BUY_IN_INVOICE}/[id]`,
        key: `${BUY_IN_INVOICE}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: SELL_OUT_INVOICE,
    href: SELL_OUT_INVOICE,
    key: SELL_OUT_INVOICE,
    display: !isIndia,
    children: [
      {
        name: DETAIL,
        href: `${SELL_OUT_INVOICE}/[id]`,
        key: `${SELL_OUT_INVOICE}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: PAYOUT_STATEMENT,
    href: PAYOUT_STATEMENT,
    key: PAYOUT_STATEMENT,
    display: true,
    children: [
      {
        name: SA_PAYOUT_STATEMENT_DETAIl,
        href: `${PAYOUT_STATEMENT}/[id]`,
        key: `${PAYOUT_STATEMENT}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: FINANCE_TASK_LIST,
    href: FINANCE_TASK_LIST,
    key: FINANCE_TASK_LIST,
    display: true,
    children: [],
  },
  {
    name: PAYMENT_REQUEST,
    href: PAYMENT_REQUEST,
    key: PAYMENT_REQUEST,
    display: !isIndia,
    children: [
      {
        name: DETAIL,
        href: `${PAYMENT_REQUEST}/[id]`,
        key: `${PAYMENT_REQUEST}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: PRICE_TYPE_CONFIG,
    href: PRICE_TYPE_CONFIG,
    key: PRICE_TYPE_CONFIG,
    display: !isIndia,
    children: [],
  },
  {
    name: CLAIMS_MANAGEMENT,
    href: CLAIMS_MANAGEMENT,
    key: CLAIMS_MANAGEMENT,
    display: isIndia,
    children: claimsChildren,
  },
  {
    name: REASON_NEED_ADJUST,
    href: REASON_NEED_ADJUST,
    key: REASON_NEED_ADJUST,
    display: !isIndia,
  },
  {
    name: TAX_MANAGEMENT,
    href: TAX_MANAGEMENT,
    key: TAX_MANAGEMENT,
    display: !isIndia,
  },
  {
    name: SUPPLIER_INVOICE_PRODUCT_MANAGEMENT,
    href: SUPPLIER_INVOICE_PRODUCT_MANAGEMENT,
    key: SUPPLIER_INVOICE_PRODUCT_MANAGEMENT,
    display: !isIndia,
  },
  {
    name: DISTRIBUTOR_CONTRACT_FEE,
    href: DISTRIBUTOR_CONTRACT_FEE,
    key: DISTRIBUTOR_CONTRACT_FEE,
    display: !isIndia,
  },
]

const wmsChildren = [
  {
    name: WAREHOUSE_WMS_LIST,
    href: WAREHOUSE_WMS_LIST,
    key: WAREHOUSE_WMS_LIST,
    display: !isIndia,
  },
  {
    name: INBOUND_LIST,
    href: INBOUND_LIST,
    key: INBOUND_LIST,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${INBOUND_LIST}/[id]`,
        key: `${INBOUND_LIST}/[id]`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${INBOUND_LIST}/retail-inbound/[id]`,
        key: `${INBOUND_LIST}/retail-inbound/[id]`,
        display: false,
      },
    ],
  },
  {
    name: INVENTORY_LIST,
    href: INVENTORY_LIST,
    key: INVENTORY_LIST,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${INVENTORY_LIST}/[id]`,
        key: `${INVENTORY_LIST}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: INVENTORY_DISTRIBUTOR_AGENT,
    href: INVENTORY_DISTRIBUTOR_AGENT,
    key: INVENTORY_DISTRIBUTOR_AGENT,
    display: !isIndia,
    children: [
      {
        name: DETAIL,
        href: `${INVENTORY_DISTRIBUTOR_AGENT}/[id]`,
        key: `${INVENTORY_DISTRIBUTOR_AGENT}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: INVENTORY_ALLOCATION,
    href: INVENTORY_ALLOCATION,
    key: INVENTORY_ALLOCATION,
    display: !isIndia,
    children: [
      {
        name: CREATE,
        href: `${INVENTORY_ALLOCATION}/create`,
        key: `${INVENTORY_ALLOCATION}/create`,
        display: false,
      },
    ],
  },
  {
    name: INVENTORY_ADJUSTMENT,
    href: INVENTORY_ADJUSTMENT,
    key: INVENTORY_ADJUSTMENT,
    display: isIndia,
    children: [
      {
        name: DETAIL,
        href: `${INVENTORY_ADJUSTMENT}/[id]`,
        key: `${INVENTORY_ADJUSTMENT}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: AUTO_INVENTORY_REPLENISHMENT,
    href: AUTO_INVENTORY_REPLENISHMENT,
    key: AUTO_INVENTORY_REPLENISHMENT,
    display: !isIndia,
  },
  {
    name: STOCK_TRANSFER,
    href: STOCK_TRANSFER,
    key: STOCK_TRANSFER,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${STOCK_TRANSFER}/[id]`,
        key: `${STOCK_TRANSFER}/[id]`,
        display: false,
      },
      {
        name: CREATE,
        href: `${STOCK_TRANSFER}/create`,
        key: `${STOCK_TRANSFER}/create`,
        display: false,
      },
    ],
  },
]

const fulfillmentChildren = [
  {
    name: TRADE_ORDERS,
    href: TRADE_ORDERS,
    key: TRADE_ORDERS,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${TRADE_ORDERS}/[id]`,
        key: `${TRADE_ORDERS}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: PRE_ORDERS,
    href: PRE_ORDERS,
    key: PRE_ORDERS,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${PRE_ORDERS}/[id]`,
        key: `${PRE_ORDERS}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: ORDER_LIST,
    href: ORDER_LIST,
    key: ORDER_LIST,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${ORDER_LIST}/[id]`,
        key: `${ORDER_LIST}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: PICKLIST_ASSIGNMENT,
    href: PICKLIST_ASSIGNMENT,
    key: PICKLIST_ASSIGNMENT,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${PICKLIST_ASSIGNMENT}/[id]`,
        key: `${PICKLIST_ASSIGNMENT}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: PICKLIST_CHECKOUT,
    href: PICKLIST_CHECKOUT,
    key: PICKLIST_CHECKOUT,
    display: false,
  },
]

const riderDeliveryChildren = [
  {
    name: RIDER_MANAGEMENT,
    href: RIDER_MANAGEMENT,
    key: RIDER_MANAGEMENT,
    display: true,
    children: [],
  },
  {
    name: VEHICLE_MANAGEMENT,
    href: VEHICLE_MANAGEMENT,
    key: VEHICLE_MANAGEMENT,
    display: true,
    children: [],
  },
  {
    name: DELIVERY_MANAGEMENT,
    href: DELIVERY_MANAGEMENT,
    key: DELIVERY_MANAGEMENT,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${DELIVERY_MANAGEMENT}/[id]`,
        key: `${DELIVERY_MANAGEMENT}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: DELIVERY_MANAGEMENT_HUB,
    href: DELIVERY_MANAGEMENT_HUB,
    key: DELIVERY_MANAGEMENT_HUB,
    display: isIndia,
    children: [
      {
        name: DETAIL,
        href: `${DELIVERY_MANAGEMENT_HUB}/[id]`,
        key: `${DELIVERY_MANAGEMENT_HUB}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: FAILED_DELIVERY_RECEIVE,
    href: FAILED_DELIVERY_RECEIVE,
    key: FAILED_DELIVERY_RECEIVE,
    display: !isIndia,
    children: [],
  },
  {
    name: PACKAGE_LIST,
    href: PACKAGE_LIST,
    key: PACKAGE_LIST,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${PACKAGE_LIST}/[id]`,
        key: `${PACKAGE_LIST}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: REMITTANCE_LIST,
    href: REMITTANCE_LIST,
    key: REMITTANCE_LIST,
    display: !isIndia,
    children: [
      {
        name: DETAIL,
        href: `${REMITTANCE_LIST}/[id]`,
        key: `${REMITTANCE_LIST}/[id]`,
        display: false,
      },
      {
        name: CREATE,
        href: `${REMITTANCE_LIST}/create`,
        key: `${REMITTANCE_LIST}/create`,
        display: false,
      },
    ],
  },
]

const reportChildren = [
  {
    name: REPORT_RIDER,
    href: REPORT_RIDER,
    key: REPORT_RIDER,
    display: true,
    children: [],
  },
  {
    name: REPORT_GST_PURCHASES,
    href: REPORT_GST_PURCHASES,
    key: REPORT_GST_PURCHASES,
    display: isIndia,
    children: [
      {
        name: REPORT_GST_PURCHASE_REGISTER,
        href: REPORT_GST_PURCHASE_REGISTER,
        key: REPORT_GST_PURCHASE_REGISTER,
        display: true,
      },
      {
        name: REPORT_PURCHASE_REGISTER_DETAILED,
        href: REPORT_PURCHASE_REGISTER_DETAILED,
        key: REPORT_PURCHASE_REGISTER_DETAILED,
        display: true,
      },
      {
        name: REPORT_PURCHASE_RETURN,
        href: REPORT_PURCHASE_RETURN,
        key: REPORT_PURCHASE_RETURN,
        display: true,
      },
    ],
  },
  {
    name: REPORT_GST_SALES,
    href: REPORT_GST_SALES,
    key: REPORT_GST_SALES,
    display: isIndia,
    children: [
      {
        name: REPORT_GSTR_SALES_REGISTER,
        href: REPORT_GSTR_SALES_REGISTER,
        key: REPORT_GSTR_SALES_REGISTER,
        display: true,
      },
      {
        name: REPORT_GST_SALES_RETURN,
        href: REPORT_GST_SALES_RETURN,
        key: REPORT_GST_SALES_RETURN,
        display: true,
      },
    ],
  },
  {
    name: REPORT_GST_SALES_ADMIN,
    href: REPORT_GST_SALES_ADMIN,
    key: REPORT_GST_SALES_ADMIN,
    display: isIndia,
    children: [
      {
        name: REPORT_GST_SALES_REGISTER_ADMIN,
        href: REPORT_GST_SALES_REGISTER_ADMIN,
        key: REPORT_GST_SALES_REGISTER_ADMIN,
        display: true,
      },
      {
        name: REPORT_GST_SALES_RETURN_ADMIN,
        href: REPORT_GST_SALES_RETURN_ADMIN,
        key: REPORT_GST_SALES_RETURN_ADMIN,
        display: true,
      },
    ],
  },
  {
    name: REPORT_GST_SALES_HUB,
    href: REPORT_GST_SALES_HUB,
    key: REPORT_GST_SALES_HUB,
    display: isIndia,
    children: [
      {
        name: REPORT_GST_SALES_REGISTER_HUB,
        href: REPORT_GST_SALES_REGISTER_HUB,
        key: REPORT_GST_SALES_REGISTER_HUB,
        display: true,
      },
      {
        name: REPORT_GST_SALES_REGISTER_DETAILED_HUB,
        href: REPORT_GST_SALES_REGISTER_DETAILED_HUB,
        key: REPORT_GST_SALES_REGISTER_DETAILED_HUB,
        display: true,
      },
      {
        name: REPORT_GST_SALES_RETURN_HUB,
        href: REPORT_GST_SALES_RETURN_HUB,
        key: REPORT_GST_SALES_RETURN_HUB,
        display: true,
      },
    ],
  },
  {
    name: REPORT_SUMMARY,
    href: REPORT_SUMMARY,
    key: REPORT_SUMMARY,
    display: isIndia,
    children: [
      {
        name: REPORT_GST_SUMMARY_BY_HSN,
        href: REPORT_GST_SUMMARY_BY_HSN,
        key: REPORT_GST_SUMMARY_BY_HSN,
        display: true,
      },
      {
        name: REPORT_INVOICE_LOADING,
        href: REPORT_INVOICE_LOADING,
        key: REPORT_INVOICE_LOADING,
        display: true,
      },
      {
        name: REPORT_PRODUCT_SUMMARY,
        href: REPORT_PRODUCT_SUMMARY,
        key: REPORT_PRODUCT_SUMMARY,
        display: true,
      },
    ],
  },
  {
    name: REPORT_INVENTORY,
    href: REPORT_INVENTORY,
    key: REPORT_INVENTORY,
    display: isIndia,
    children: [
      {
        name: REPORT_INVENTORY_SNAPSHOT,
        href: REPORT_INVENTORY_SNAPSHOT,
        key: REPORT_INVENTORY_SNAPSHOT,
        display: true,
      },
      {
        name: REPORT_PURCHASE_RECONCILIATION,
        href: REPORT_PURCHASE_RECONCILIATION,
        key: REPORT_PURCHASE_RECONCILIATION,
        display: true,
      },
    ],
  },
  {
    name: REPORT_CLAIMS,
    href: REPORT_CLAIMS,
    key: REPORT_CLAIMS,
    display: isIndia,
    children: [
      {
        name: REPORT_CLAIM,
        href: REPORT_CLAIM,
        key: REPORT_CLAIM,
        display: true,
      },
      {
        name: REPORT_CLAIMS_RETURN,
        href: REPORT_CLAIMS_RETURN,
        key: REPORT_CLAIMS_RETURN,
        display: true,
      },
    ],
  },
  {
    name: REPORT_DISTRIBUTOR_RECONCILIATION,
    href: REPORT_DISTRIBUTOR_RECONCILIATION,
    key: REPORT_DISTRIBUTOR_RECONCILIATION,
    display: !isIndia,
  },
  {
    name: REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY,
    href: REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY,
    key: REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY,
    display: !isIndia,
  },
  {
    name: REPORT_SALES_SUMMARY,
    href: REPORT_SALES_SUMMARY,
    key: REPORT_SALES_SUMMARY,
    display: isIndia,
    children: [
      {
        name: REPORT_SALES_SUMMARY_SKU,
        href: REPORT_SALES_SUMMARY_SKU,
        key: REPORT_SALES_SUMMARY_SKU,
        display: true,
      },
      {
        name: REPORT_SALES_SUMMARY_OUTLET,
        href: REPORT_SALES_SUMMARY_OUTLET,
        key: REPORT_SALES_SUMMARY_OUTLET,
        display: true,
      },
    ],
  },
]

const marketingChildren = [
  {
    name: MARKETING_BUDGET,
    href: MARKETING_BUDGET,
    key: MARKETING_BUDGET,
    display: !isIndia,
  },
  {
    name: SUBSIDY_RULE,
    href: SUBSIDY_RULE,
    key: SUBSIDY_RULE,
    display: !isIndia,
    children: [
      {
        name: DETAIL,
        href: `${SUBSIDY_RULE}/[id]`,
        key: `${SUBSIDY_RULE}/[id]`,
        display: false,
      },
      {
        name: CREATE,
        href: `${SUBSIDY_RULE}/create`,
        key: `${SUBSIDY_RULE}/create`,
        display: false,
      },
    ],
  },
  {
    name: AGENT_INCENTIVES,
    href: AGENT_INCENTIVES,
    key: AGENT_INCENTIVES,
    display: true,
    children: [
      {
        name: AGENT_GROUP,
        href: AGENT_GROUP,
        key: AGENT_GROUP,
        display: true,
      },
      {
        name: INCENTIVES_CONFIG,
        href: INCENTIVES_CONFIG,
        key: INCENTIVES_CONFIG,
        display: true,
        children: [
          {
            name: CREATE,
            href: `${INCENTIVES_CONFIG}/create`,
            key: `${INCENTIVES_CONFIG}/create`,
            display: false,
          },
          {
            name: EDIT,
            href: `${INCENTIVES_CONFIG}/update/[id]`,
            key: `${INCENTIVES_CONFIG}/update/[id]`,
            display: false,
          },
        ],
      },
    ],
  },
]

const distributorCenterChildren = [
  {
    name: PURCHASING,
    href: PURCHASING,
    key: PURCHASING,
    display: !isIndia,
    children: [
      //Purchase Request
      {
        name: PURCHASE_REQUEST,
        href: `${PURCHASING}/purchase-request`,
        key: `${PURCHASING}/purchase-request`,
        display: false,
        children: [
          {
            name: CREATE,
            href: `${PURCHASING}/purchase-request/create`,
            key: `${PURCHASING}/purchase-request/create`,
            display: false,
          },
          {
            name: DETAIL,
            href: `${PURCHASING}/purchase-request/[id]`,
            key: `${PURCHASING}/purchase-request/[id]`,
            display: false,
          },
        ],
      },
      //Vigo Quotation
      {
        name: VIGO_QUOTATION,
        href: `${PURCHASING}/vigo-quotation`,
        key: `${PURCHASING}/vigo-quotation`,
        display: false,
        children: [
          {
            name: DETAIL,
            href: `${PURCHASING}/vigo-quotation/[id]`,
            key: `${PURCHASING}/vigo-quotation/[id]`,
            display: false,
          },
        ],
      },
      //Purchase Order
      {
        name: DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB,
        href: `${PURCHASING}/distributor-purchase-order`,
        key: `${PURCHASING}/distributor-purchase-order`,
        display: false,
        children: [
          {
            name: DETAIL,
            href: `${PURCHASING}/distributor-purchase-order/[id]`,
            key: `${PURCHASING}/distributor-purchase-order/[id]`,
            display: false,
          },
        ],
      },
      //Payment Request
      {
        name: PAYMENT_REQUEST_BREADCRUMB,
        href: `${PURCHASING}/payment-request`,
        key: `${PURCHASING}/payment-request`,
        display: false,
        children: [
          {
            name: DETAIL,
            href: `${PURCHASING}/payment-request/[id]`,
            key: `${PURCHASING}/payment-request/[id]`,
            display: false,
          },
        ],
      },
    ],
  },
  {
    name: CREDIT,
    href: CREDIT,
    key: CREDIT,
    display: isIndia,
    children: [],
  },
]

const procurementChildren = [
  {
    name: DISTRIBUTOR_PURCHASE_REQUEST,
    href: DISTRIBUTOR_PURCHASE_REQUEST,
    key: DISTRIBUTOR_PURCHASE_REQUEST,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${DISTRIBUTOR_PURCHASE_REQUEST}/[id]`,
        key: `${DISTRIBUTOR_PURCHASE_REQUEST}/[id]`,
        display: false,
      },
      {
        name: CREATE,
        href: `${DISTRIBUTOR_PURCHASE_REQUEST}/create-retail`,
        key: `${DISTRIBUTOR_PURCHASE_REQUEST}/create-retail`,
        display: false,
      },
      {
        name: CREATE,
        href: `${DISTRIBUTOR_PURCHASE_REQUEST}/create-consignment`,
        key: `${DISTRIBUTOR_PURCHASE_REQUEST}/create-consignment`,
        display: false,
      },
    ],
  },
  {
    name: CONSOLIDATED_PURCHASE_REQUEST,
    href: CONSOLIDATED_PURCHASE_REQUEST,
    key: CONSOLIDATED_PURCHASE_REQUEST,
    display: true,
    children: [
      {
        name: EDIT,
        href: `${CONSOLIDATED_PURCHASE_REQUEST}/[id]`,
        key: `${CONSOLIDATED_PURCHASE_REQUEST}/[id]`,
        display: false,
      },
      {
        name: CREATE,
        href: `${CONSOLIDATED_PURCHASE_REQUEST}/create`,
        key: `${CONSOLIDATED_PURCHASE_REQUEST}/create`,
        display: false,
      },
    ],
  },
  {
    name: VIGO_MASTER_QUOTATION,
    href: VIGO_MASTER_QUOTATION,
    key: VIGO_MASTER_QUOTATION,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${VIGO_MASTER_QUOTATION}/[id]`,
        key: `${VIGO_MASTER_QUOTATION}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: DISTRIBUTOR_PURCHASE_ORDER,
    href: DISTRIBUTOR_PURCHASE_ORDER,
    key: DISTRIBUTOR_PURCHASE_ORDER,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${DISTRIBUTOR_PURCHASE_ORDER}/[id]`,
        key: `${DISTRIBUTOR_PURCHASE_ORDER}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: VIGO_PURCHASE_ORDER,
    href: VIGO_PURCHASE_ORDER,
    key: VIGO_PURCHASE_ORDER,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${VIGO_PURCHASE_ORDER}/[id]`,
        key: `${VIGO_PURCHASE_ORDER}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: INVENTORY_RECOMMEND,
    href: INVENTORY_RECOMMEND,
    key: INVENTORY_RECOMMEND,
    display: !isIndia,
    children: [],
  },
  {
    name: REASON_CANCEL,
    href: REASON_CANCEL,
    key: REASON_CANCEL,
    display: !isIndia,
    children: [],
  },
  {
    name: AUTOMATIC_PROCUREMENT_CONFIG,
    href: AUTOMATIC_PROCUREMENT_CONFIG,
    key: AUTOMATIC_PROCUREMENT_CONFIG,
    display: !isIndia,
    children: [],
  },
  {
    name: AUTOMATIC_PARTIAL_FULFILLMENT,
    href: AUTOMATIC_PARTIAL_FULFILLMENT,
    key: AUTOMATIC_PARTIAL_FULFILLMENT,
    display: !isIndia,
    children: [],
  },
  {
    name: GOODS_NOT_FOCUS,
    href: GOODS_NOT_FOCUS,
    key: GOODS_NOT_FOCUS,
    display: !isIndia,
    children: [],
  },
  {
    name: CONFIG_CM3_TARGET,
    href: CONFIG_CM3_TARGET,
    key: CONFIG_CM3_TARGET,
    display: !isIndia,
    children: [],
  },
]

const logisticsChildren = [
  {
    name: TRANSPORTATION_VENDOR,
    href: TRANSPORTATION_VENDOR,
    key: TRANSPORTATION_VENDOR,
    display: true,
    children: [],
  },
  {
    name: VIGO_DELIVERY,
    href: VIGO_DELIVERY,
    key: VIGO_DELIVERY,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${VIGO_DELIVERY}/[id]`,
        key: `${VIGO_DELIVERY}/[id]`,
        display: false,
      },
    ],
  },
]

const returnChildren = [
  {
    name: RETURN_ORDER_LIST,
    href: RETURN_ORDER_LIST,
    key: RETURN_ORDER_LIST,
    display: true,
    children: [
      {
        name: DETAIL,
        href: `${RETURN_ORDER_LIST}/[id]`,
        key: `${RETURN_ORDER_LIST}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: CREDIT_DEBIT_NOTE,
    href: CREDIT_DEBIT_NOTE,
    key: CREDIT_DEBIT_NOTE,
    display: true,
    children: [],
  },
]

const distributorManagementChildren = [
  {
    name: DISTRIBUTOR_PROFILE,
    href: DISTRIBUTOR_PROFILE,
    key: DISTRIBUTOR_PROFILE,
    display: !isIndia,
    children: [
      {
        name: CREATE,
        href: `${DISTRIBUTOR_PROFILE}/create`,
        key: `${DISTRIBUTOR_PROFILE}/create`,
        display: false,
      },
      {
        name: DETAIL,
        href: `${DISTRIBUTOR_PROFILE}/[id]`,
        key: `${DISTRIBUTOR_PROFILE}/[id]`,
        display: false,
      },
    ],
  },
  {
    name: DISTRIBUTOR_REGISTER,
    href: DISTRIBUTOR_REGISTER,
    key: DISTRIBUTOR_REGISTER,
    display: !isIndia,
    children: [
      {
        name: DETAIL,
        href: `${DISTRIBUTOR_REGISTER}/[id]`,
        key: `${DISTRIBUTOR_REGISTER}/[id]`,
        display: false,
      },
    ],
  },
]

export const mainNavigationTree: INavigation = {
  name: HOME,
  href: HOME,
  key: HOME,
  icon: <HomeOutlined />,
  display: true,
  children: [
    {
      name: NOTIFICATION,
      href: NOTIFICATION,
      key: NOTIFICATION,
      // icon: <ControlOutlined />,
      display: false,
      // children: masterDataChildren,
    },
    {
      name: MASTER_DATA,
      href: MASTER_DATA,
      key: MASTER_DATA,
      icon: <ControlOutlined />,
      display: isIndia,
      children: masterDataChildren,
    },
    {
      name: MENU_PRODUCT,
      href: MENU_PRODUCT,
      key: MENU_PRODUCT,
      icon: <ShoppingOutlined />,
      display: !isIndia,
      children: productChildren,
    },
    {
      name: PARTNER,
      href: PARTNER,
      key: PARTNER,
      icon: <TeamOutlined />,
      display: !isIndia,
      children: partnerChildren,
    },
    {
      name: AGENT,
      href: AGENT,
      key: AGENT,
      icon: <UserSwitchOutlined />,
      display: !isIndia,
      children: agentChildren,
    },
    {
      name: SUPPLIER_PORTAL,
      href: SUPPLIER_PORTAL,
      key: SUPPLIER_PORTAL,
      icon: <ShopOutlined />,
      display: true,
      children: supplierChildren,
    },
    {
      name: BRAND_PORTAL,
      href: BRAND_PORTAL,
      key: BRAND_PORTAL,
      icon: <SketchOutlined />,
      display: !isIndia,
      children: brandChildren,
    },
    {
      name: DISTRIBUTOR_CENTER,
      href: DISTRIBUTOR_CENTER,
      key: DISTRIBUTOR_CENTER,
      icon: <DeploymentUnitOutlined />,
      display: true,
      children: distributorCenterChildren,
    },
    {
      name: PROCUREMENT,
      href: PROCUREMENT,
      key: PROCUREMENT,
      icon: <PullRequestOutlined />,
      display: !isIndia,
      children: procurementChildren,
    },
    {
      name: LOGISTICS,
      href: LOGISTICS,
      key: LOGISTICS,
      icon: <ReconciliationOutlined />,
      display: !isIndia,
      children: logisticsChildren,
    },
    {
      name: WMS,
      href: WMS,
      key: WMS,
      icon: <GoldOutlined />,
      display: true,
      children: wmsChildren,
    },
    {
      name: FULFILLMENTS,
      href: FULFILLMENTS,
      key: FULFILLMENTS,
      icon: <FileDoneOutlined />,
      display: true,
      children: fulfillmentChildren,
    },
    {
      name: RETURN,
      href: RETURN,
      key: RETURN,
      icon: <RetweetOutlined />,
      display: isIndia,
      children: returnChildren,
    },
    {
      name: FINANCE,
      href: FINANCE,
      key: FINANCE,
      icon: <DollarOutlined />,
      display: true,
      children: financeChildren,
    },
    {
      name: RIDER,
      href: RIDER,
      key: RIDER,
      icon: <CarOutlined />,
      display: true,
      children: riderDeliveryChildren,
    },
    {
      name: DISTRIBUTOR_MANAGEMENT,
      href: DISTRIBUTOR_MANAGEMENT,
      key: DISTRIBUTOR_MANAGEMENT,
      icon: <ContactsOutlined />,
      display: !isIndia,
      children: distributorManagementChildren,
    },
    {
      name: REPORT,
      href: REPORT,
      key: REPORT,
      icon: <ProfileOutlined />,
      display: true,
      children: reportChildren,
    },
    {
      name: MARKETING,
      href: MARKETING,
      key: MARKETING,
      icon: <ShoppingCartOutlined />,
      display: true,
      children: marketingChildren,
    },
    {
      name: OUTLET_MANAGEMENT,
      href: OUTLET_MANAGEMENT,
      key: OUTLET_MANAGEMENT,
      icon: <ShopOutlined />,
      display: isIndia,
      children: [
        {
          name: CREATE,
          href: `${OUTLET_MANAGEMENT}/list`,
          key: `${OUTLET_MANAGEMENT}/list`,
          display: false,
        },
      ],
    },
    {
      name: SETTINGS,
      href: SETTINGS,
      key: SETTINGS,
      icon: <SettingOutlined />,
      display: true,
      children: settingChildren,
    },
  ],
}

export const getPathByKey = (
  element: INavigation,
  href: string
): INavigation[] | undefined => {
  let path
  const item = element
  if (!element || typeof element !== 'object') return
  if (element.key === href) return [item]
  const children = element.children || []
  children.some((child) => (path = getPathByKey(child, href)))
  return path && [item, ...path]
}
