import { memo } from 'react'

interface IProps {
  title: string
}

const CardPageTitle: React.FC<IProps> = ({ title }) => {
  return <h1 className="text-lg font-bold mb-0">{title}</h1>
}

export default memo(CardPageTitle)
