export const DISTRIBUTOR_TYPE_NORMAL = 'normal'
export const DISTRIBUTOR_TYPE_MASTER_DISTRIBUTOR_HUB = 'master_distributor_hub'
export const SOURCE_DISTRIBUTOR = 'distributor'
export const SOURCE_VIGO = 'vigo'
export const SOURCE_ALL = 'all'

export const INDUSTRY_PHARMACY = 'pharmacy'
export const INDUSTRY_FMCG = 'fmcg'

export const INDUSTRY_OPTION = [
  { label: 'FMCG', value: 'fmcg' },
  { label: 'Pharmacy', value: 'pharmacy' },
]

export const SOURCE_OPTION = [
  { label: 'All', value: SOURCE_ALL },
  { label: 'Vigo', value: SOURCE_VIGO },
  { label: 'Distributor', value: SOURCE_DISTRIBUTOR },
]

export const STATUS_HISTORY_BANK_COLLECTION = [
  'bankCollectionName',
  'bankCollectionAccountNumber',
  'bankCollectionAccountName',
  'bankCollectionBranchCode',
]

export const STATUS_HISTORY_BANK_BUSINESS = [
  'bankName',
  'bankAccountNumber',
  'bankAccountName',
  'bankBranchCode',
]

export const STATUS_HISTORY_BILLING_ADDRESS = [
  'billingAddressCoordinateLat',
  'billingAddressCoordinateLong',
  'billingAddressDetail',
]

export const STATUS_HISTORY_SHIPPING_ADDRESS = [
  'shippingAddressCoordinateLat',
  'shippingAddressCoordinateLong',
  'shippingAddressDetail',
]

export const DISTRIBUTOR_SUBTYPE_DC_REGULAR = 'dc_regular'
export const DISTRIBUTOR_SUBTYPE_DC_DIRECT = 'dc_direct'
export const DISTRIBUTOR_SUBTYPE_DC_WS = 'dc_ws'
export const DISTRIBUTOR_SUBTYPE_DC_SUPER = 'dc_super'
export const DISTRIBUTOR_SUBTYPE_WS_DIRECT = 'ws_direct'
export const DISTRIBUTOR_SUBTYPE_DIRECT_MICRO_DC = 'direct_micro_dc'
export const DISTRIBUTOR_SUBTYPE_INDIRECT_MICRO_DC = 'indirect_micro_dc'

export const DISTRIBUTOR_TYPE_MAPPING_LABEL = {
  [DISTRIBUTOR_SUBTYPE_DC_REGULAR]: 'DC Regular',
  [DISTRIBUTOR_SUBTYPE_DC_DIRECT]: 'DC Direct',
  [DISTRIBUTOR_SUBTYPE_DC_WS]: 'DC WS',
  [DISTRIBUTOR_SUBTYPE_DC_SUPER]: 'DC Super',
  [DISTRIBUTOR_SUBTYPE_WS_DIRECT]: 'WS Direct',
  [DISTRIBUTOR_SUBTYPE_DIRECT_MICRO_DC]: 'Direct Micro DC',
  [DISTRIBUTOR_SUBTYPE_INDIRECT_MICRO_DC]: 'Indirect Micro DC',
}
