import {
  CarryOutOutlined,
  ContainerOutlined,
  DollarCircleOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import { Card } from 'antd'
import { FULFILLMENTS, PURCHASING } from 'common/config/routes'
import { PURCHASE_REQUEST_RECOMMENDED } from 'utils/purchaseRequest'
import { TO_DO_LIST, tracking } from 'utils/tracking'
import {
  EVENT_VIEW_PURCHASE_REQUEST_LIST,
  PROPERTIES_SHORTCUTS_PURCHASING_MAPPING,
} from 'utils/tracking/pages/purchasing'

const PATH_NAME_STATUS_RECOMMENDED_PR = 'purchase-request?status=recommended'
const PATH_NAME_STATUS_AWAITING_PAYMENT_DPO =
  'distributor-purchase-order?status=awaiting_payment'
const PATH_NAME_STATUS_CREATED_ORDER = 'orders?status=created'
const PATH_NAME_STATUS_CREATED_VQ = 'vigo-quotation?status=created'

const ICON_MAPPING = {
  [PATH_NAME_STATUS_RECOMMENDED_PR]: <CarryOutOutlined />,
  [PATH_NAME_STATUS_AWAITING_PAYMENT_DPO]: <DollarCircleOutlined />,
  [PATH_NAME_STATUS_CREATED_VQ]: <ContainerOutlined />,
  [PATH_NAME_STATUS_CREATED_ORDER]: <ShoppingCartOutlined />,
}

const ICON_COLOR_MAPPING = {
  [PATH_NAME_STATUS_RECOMMENDED_PR]: 'text-[#faad14]',
  [PATH_NAME_STATUS_AWAITING_PAYMENT_DPO]: 'text-[#0da871]',
  [PATH_NAME_STATUS_CREATED_VQ]: 'text-[#ff5631]',
  [PATH_NAME_STATUS_CREATED_ORDER]: 'text-[#7b61ff]',
}

const ShortcutItem = ({
  totalPending,
  label,
  pathName,
  distributorSelected,
}: {
  totalPending: number
  label: string
  pathName: string
  distributorSelected: string
}) => {
  const handleClick = () => {
    if (pathName === PATH_NAME_STATUS_RECOMMENDED_PR) {
      const properties = {
        source_screen: TO_DO_LIST,
        status: PURCHASE_REQUEST_RECOMMENDED.toLocaleUpperCase(),
      }
      tracking(
        EVENT_VIEW_PURCHASE_REQUEST_LIST,
        properties,
        PROPERTIES_SHORTCUTS_PURCHASING_MAPPING
      )
    }
    const url =
      pathName !== PATH_NAME_STATUS_CREATED_ORDER
        ? `${PURCHASING}/${pathName}&distributorID=${distributorSelected}`
        : `${FULFILLMENTS}/${pathName}&distributorID=${distributorSelected}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Card hoverable className="rounded-lg" onClick={handleClick}>
      <div className="h-20 grid grid-cols-1 content-between ">
        <div className="flex space-x-2">
          <div
            className={`text-xl flex items-start ${ICON_COLOR_MAPPING[pathName]}`}
          >
            {ICON_MAPPING[pathName]}
          </div>
          <div>{label}</div>
        </div>

        <div className="flex justify-end text-2xl bold">{totalPending}</div>
      </div>
    </Card>
  )
}

interface IProps {
  totalRecommendedPurchaseRequest: number
  totalCreatedVigoQuotation: number
  totalAwaitingPaymentDPO: number
  totalCreatedOrder: number
  distributorSelected: string
  translate: (value: string) => string
}

const Shortcuts = ({
  totalRecommendedPurchaseRequest,
  totalCreatedVigoQuotation,
  totalAwaitingPaymentDPO,
  totalCreatedOrder,
  distributorSelected,
  translate,
}: IProps) => {
  return (
    <div className="flex justify-center grid grid-cols-4 gap-4 mb-8 p-4">
      <ShortcutItem
        label={translate('labelRecommendedPurchaseOrder')}
        totalPending={totalRecommendedPurchaseRequest}
        pathName={PATH_NAME_STATUS_RECOMMENDED_PR}
        distributorSelected={distributorSelected}
      />
      <ShortcutItem
        label={translate('labelCreatedVigoQuotation')}
        totalPending={totalCreatedVigoQuotation}
        pathName={PATH_NAME_STATUS_CREATED_VQ}
        distributorSelected={distributorSelected}
      />
      <ShortcutItem
        label={translate('labelAwaitingPaymentDPO')}
        totalPending={totalAwaitingPaymentDPO}
        pathName={PATH_NAME_STATUS_AWAITING_PAYMENT_DPO}
        distributorSelected={distributorSelected}
      />
      <ShortcutItem
        label={translate('labelCreatedOrder')}
        totalPending={totalCreatedOrder}
        pathName={PATH_NAME_STATUS_CREATED_ORDER}
        distributorSelected={distributorSelected}
      />
    </div>
  )
}

export default Shortcuts
