import { Drawer } from 'antd'
import { IMessages } from 'interfaces/Notification'
import { FC, memo } from 'react'

interface IProps {
  visible: boolean
  onCancel: () => void
  detailNotification: IMessages
}

const ViewDetailNotificationDrawer: FC<IProps> = ({
  visible,
  onCancel,
  detailNotification,
}) => {
  return (
    <Drawer
      placement="left"
      width="60vw"
      open={visible}
      closable={false}
      onClose={onCancel}
    >
      <div>
        <div className="text-2xl mb-4">{detailNotification?.title}</div>
        {detailNotification?.schedule?.image && (
          <div>
            <img
              src={detailNotification?.schedule?.image}
              loading="lazy"
              alt=""
              className="object-contain mb-4"
            />
          </div>
        )}

        <div
          dangerouslySetInnerHTML={{ __html: detailNotification?.content }}
        />
      </div>
    </Drawer>
  )
}

export default memo(ViewDetailNotificationDrawer)
