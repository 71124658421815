import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { Button, Carousel, Modal } from 'antd'
import { HOME } from 'common/config/routes'
import { IMessages } from 'interfaces/Notification'
import { useTranslations } from 'next-intl'
import React, { useRef } from 'react'

interface IProps {
  visible: boolean
  onCancel: () => void
  notifications: IMessages[]
  setDetailNotification: (value: IMessages[]) => void
  setVisible: (value: boolean) => void
}

const NotificationModal: React.FC<IProps> = ({
  visible,
  onCancel,
  notifications,
  setDetailNotification,
  setVisible,
}) => {
  const carouselRef = useRef(null)
  const translate = useTranslations(HOME)

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next()
    }
  }

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev()
    }
  }

  const handleClick = (item: IMessages) => {
    setDetailNotification([item])
    onCancel()
    setVisible(true)
  }

  const renderCarousel = (notifications: IMessages[]) => {
    if (notifications?.length > 0) {
      return notifications?.slice(0, 5)?.map((item) => {
        return (
          <div
            key={item?.id}
            className="grid grid-cols-5 gap-0 h-[50vh] cursor-pointer"
            onClick={() => handleClick(item)}
          >
            <div className="col-span-3 pt-8 pl-8 ">
              <div className="text-2xl font-bold">{item?.title}</div>
              <div
                className="text-base overflow-auto h-[70%]"
                dangerouslySetInnerHTML={{ __html: item?.content }}
              />
            </div>

            <div className="h-[50vh] col-span-2">
              <img
                loading="lazy"
                src={item?.schedule?.image}
                alt=""
                className="h-full w-full object-fill opacity-95"
              />
            </div>
          </div>
        )
      })
    }
  }

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width="60vw"
      closeIcon={<CloseOutlined className="font-bold text-xl text-black " />}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
      footer={
        <div className="flex justify-between">
          <Button
            className="hover:bg-blue-light hover:text-white"
            type="link"
            onClick={handlePrev}
            icon={<ArrowLeftOutlined />}
          >
            {translate('back')}
          </Button>
          <Button
            type="link"
            className="hover:bg-blue-light hover:text-white"
            onClick={handleNext}
          >
            {translate('next')}
            <ArrowRightOutlined />
          </Button>
        </div>
      }
    >
      <Carousel ref={carouselRef} autoplay>
        {renderCarousel(notifications)}
      </Carousel>
    </Modal>
  )
}

export default React.memo(NotificationModal)
