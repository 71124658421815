import { message } from 'antd'
import axios from 'axios'
import getConfig from 'next/config'
import router from 'next/router'
import { getSession } from 'next-auth/react'
import qs, { stringify } from 'qs'

import { defaultStringifyOption, uniqueArray } from './commonUtils'

const { publicRuntimeConfig } = getConfig()
const authRequired = publicRuntimeConfig.authRequired === 'true'

const defaultOptions = {
  baseURL: publicRuntimeConfig.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
}

const axiosInstance = axios.create(defaultOptions)

const removeDuplicate = (data) =>
  Object.entries(data).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value) ? uniqueArray(value) : value,
    }),
    {}
  )

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(async (config) => {
  if (config.method === 'get') {
    const urlPath = config?.url.split('?')?.[0]
    const params = config?.url.split('?')?.[1]
    if (params) {
      const uniqueData = removeDuplicate(qs.parse(params))
      const newParams = stringify(uniqueData, defaultStringifyOption)

      config.url = `${urlPath}?${newParams}`
    }
  }
  let token = localStorage.getItem('accessToken')
  if (!token) {
    const session = await getSession()
    if (session?.accessToken) {
      token = session.accessToken as string
      localStorage.setItem('accessToken', token)
    }
  }
  config.headers.Authorization = authRequired && token ? `Bearer ${token}` : ''

  return config
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log({ error })
    if (error.response.status === 401) {
      message.error('Your session has expired!')
      localStorage.removeItem('accessToken')
      localStorage.setItem('temp-redirect', location.pathname)
      router.push('/')
    } else {
      return Promise.reject(error)
    }
  }
)

export default axiosInstance
