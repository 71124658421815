import { BellOutlined } from '@ant-design/icons'
import { Avatar, Badge, Button, Empty, List, Popover, Spin, Tabs } from 'antd'
import { HOME, NOTIFICATION } from 'common/config/routes'
import useLanguage from 'hooks/useLanguage'
import { IMessages, INotification } from 'interfaces/Notification'
import moment from 'moment'
import router from 'next/router'
import { useTranslations } from 'next-intl'
import React from 'react'
import { NOTIFICATION_UNREAD } from 'utils/notification'

interface IProps {
  notifications?: INotification
  setDetailNotification: (value: IMessages[]) => void
  setVisible: (value: boolean) => void
  onMarkAllRead: () => void
  setIsTabUnread: (value: boolean) => void
  isTabUnread?: boolean
}

const NotificationPopover: React.FC<IProps> = ({
  notifications,
  setDetailNotification,
  setVisible,
  onMarkAllRead,
  setIsTabUnread,
  isTabUnread,
}) => {
  const translate = useTranslations(HOME)
  const language = useLanguage()

  const onLoadMore = () => {
    const status = isTabUnread ? NOTIFICATION_UNREAD : undefined
    router.push(`${NOTIFICATION}${status ? `?status=${status}` : ''}`)
  }

  const loadMore = (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button onClick={onLoadMore}>{translate('seeMoreNotification')}</Button>
    </div>
  )

  const handleChangeTab = (notifications: IMessages[]) => {
    return (
      <List
        itemLayout="horizontal"
        className="w-[300px]"
        size="small"
        loadMore={loadMore}
        dataSource={notifications?.slice(0, 5)}
        renderItem={(item) => {
          const leadTime = moment(item?.createdAt).locale(language).fromNow()
          return (
            <List.Item
              onClick={() => {
                setDetailNotification([item])
                setVisible(true)
              }}
              className="cursor-pointer hover:bg-[#e5f0fb]"
            >
              <List.Item.Meta
                avatar={<Avatar shape="square" src={item?.schedule?.image} />}
                title={
                  <>
                    <div>
                      {item?.title}{' '}
                      {item?.status === NOTIFICATION_UNREAD && (
                        <Badge status="processing" className="ml-2" />
                      )}
                    </div>
                    <div
                      className={`text-xs font-light text-[${
                        item?.status === NOTIFICATION_UNREAD
                          ? '#30a7e8'
                          : '#999'
                      }] `}
                    >
                      {leadTime}
                    </div>
                  </>
                }
              />
            </List.Item>
          )
        }}
      />
    )
  }
  const renderListNotification = (notifications: IMessages[]) => {
    if (notifications?.length > 0) {
      const unreadNotifications = notifications?.filter(
        (item) => item?.status === 'created'
      )
      return (
        <>
          <Tabs
            defaultActiveKey={isTabUnread ? 'unread' : 'all'}
            onChange={(key) => {
              setIsTabUnread(key === 'unread')
            }}
            items={[
              {
                label: translate('all'),
                key: 'all',
                children: handleChangeTab(notifications),
              },
              {
                label: translate('unread'),
                key: 'unread',
                children:
                  unreadNotifications?.length > 0 ? (
                    handleChangeTab(unreadNotifications)
                  ) : (
                    <div className="flex justify-center w-[300px]">
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={translate('emptyNotification')}
                      />
                    </div>
                  ),
              },
            ]}
          />
        </>
      )
    }
  }

  const handleMarkAllRead = () => {
    const createdNotifications = notifications?.messages?.filter(
      (item) => item?.status === 'created'
    )
    createdNotifications?.length && onMarkAllRead && onMarkAllRead()
  }

  return (
    <>
      <Popover
        placement="bottom"
        content={
          !notifications ? (
            <Spin />
          ) : (
            renderListNotification(notifications?.messages)
          )
        }
        title={
          <div className="flex items-center justify-between w-[300px]">
            <div>
              <span>{translate('notification')} </span>
              <span className="text-sm text-[#999]">
                ({notifications?.stats?.unread || 0})
              </span>
            </div>
            <div>
              <Button type="link" onClick={handleMarkAllRead}>
                {translate('markAllRead')}
              </Button>
            </div>
          </div>
        }
      >
        <BellOutlined className="text-white text-3xl" />
      </Popover>
    </>
  )
}

export default React.memo(NotificationPopover)
