import { Card } from 'antd'
import { PURCHASING } from 'common/config/routes'
import dayjs from 'dayjs'
import { OverViewPendingReport } from 'interfaces/overviewReport'
import { useTranslations } from 'next-intl'
import React, { memo } from 'react'
import {
  ENTITY_TYPE_DISTRIBUTOR_PO,
  PAYMENT_REQUEST_DEBT,
} from 'utils/paymentRequest'

interface IProps {
  overviewPaymentRequests: OverViewPendingReport
  distributorID?: string
}

const UpcomingDebt: React.FC<IProps> = ({
  overviewPaymentRequests,
  distributorID,
}) => {
  const translate = useTranslations()
  return (
    <Card className="mt-4 rounded-lg hover:cursor-pointer">
      <div className="text-xl font-bold">
        {translate('paymentRequest.upcomingDebtAndDue')}
      </div>
      <div className="grid grid-cols-2 mt-6">
        <div
          onClick={() =>
            window.open(
              `${PURCHASING}/payment-request?statuses=${PAYMENT_REQUEST_DEBT}&debtDueDateTo=${dayjs()
                .endOf('day')
                .toISOString()}${
                distributorID &&
                `&distributorID=${distributorID}&entityType=${ENTITY_TYPE_DISTRIBUTOR_PO}`
              }`,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          <div className="text-base text-[#c6c7c8]">
            {translate('paymentRequest.dueDebt')}
          </div>
          <div className="text-red text-2xl">
            {overviewPaymentRequests?.totalDueDebt || 0}
          </div>
        </div>
        <div
          onClick={() =>
            window.open(
              `${PURCHASING}/payment-request?statuses=${PAYMENT_REQUEST_DEBT}&debtDueDateFrom=${dayjs()
                .add(1, 'day')
                .endOf('day')
                .toISOString()}${
                distributorID &&
                `&distributorID=${distributorID}&entityType=${ENTITY_TYPE_DISTRIBUTOR_PO}`
              }`,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          <div className="text-base text-[#c6c7c8]">
            {translate('paymentRequest.upcomingDebt')}
          </div>
          <div className="text-red text-2xl">
            {overviewPaymentRequests?.totalUpcomingDebt || 0}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default memo(UpcomingDebt)
