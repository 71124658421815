import { RightOutlined } from '@ant-design/icons'
import { Badge, Button, Card, Empty, List, Spin } from 'antd'
import { NOTIFICATION } from 'common/config/routes'
import { Context } from 'components/Layout'
import { ContextActionType } from 'components/Layout/reducer'
import useLanguage from 'hooks/useLanguage'
import { DEFAULT_PAGE } from 'hooks/usePaging'
import { IMessages, INotification } from 'interfaces/Notification'
import { get } from 'lodash'
import moment from 'moment'
import router from 'next/router'
import { useSession } from 'next-auth/react'
import { stringify } from 'qs'
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import axiosAdminPortal from 'utils/axiosAdminPortal'
import { defaultStringifyOption, getResponseData } from 'utils/commonUtils'
import { DMS_USER, NOTIFICATION_UNREAD } from 'utils/notification'

import ViewDetailNotificationDrawer from './ViewDetailNotificationDrawer'

interface IProps {
  translate: (value: string) => string
}
const defaultFilters = {
  typeOfUser: DMS_USER,
  orderBy: 'createdAt desc',
  includeSchedule: true,
  page: DEFAULT_PAGE,
  perPage: 10,
}

const NotificationHomePage: React.FC<IProps> = ({ translate }) => {
  const language = useLanguage()
  const [notifications, setNotifications] = useState<IMessages[]>([])
  const [loading, setLoading] = useState(false)
  const [totalNotiUnread, setTotalNotiUnread] = useState(0)
  const [visible, setVisible] = useState(false)
  const [detailNotification, setDetailNotification] = useState<IMessages>()
  const { data: session } = useSession()
  const { state, dispatch } = useContext(Context)

  const userID = useMemo(() => get(session, 'user.id'), [session])

  const getNotifications = useCallback(async () => {
    try {
      setLoading(true)
      if (userID) {
        const params = stringify(
          { ...defaultFilters, userID, status: NOTIFICATION_UNREAD },
          defaultStringifyOption
        )
        const responses = await axiosAdminPortal.get(
          `/notifications/api/v1/nms/push-noti/messages?${params}`
        )

        const data = getResponseData<INotification>(responses)
        setNotifications(data?.messages || [])
        setTotalNotiUnread(data?.stats?.unread || 0)
      }
    } catch (err) {
      //do something
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getNotifications()
  }, [state?.refreshKey])

  const handleCancel = async () => {
    try {
      setLoading(true)
      if (detailNotification?.status === 'created') {
        const payload = {
          messageIDs: [detailNotification?.id],
          typeOfUser: DMS_USER,
        }
        await axiosAdminPortal.post(
          `/notifications/api/v1/nms/push-noti/messages/ack`,
          payload
        )

        dispatch({
          type: ContextActionType.REFRESH_NOTIFICATION,
          payload: {
            refreshKey: state.refreshKey + 1,
          },
        })
      }
    } catch (err) {
      //do something
    } finally {
      setLoading(false)
      setVisible(false)
      setDetailNotification(undefined)
    }
  }

  const renderListNotification = (notifications: IMessages[]) => {
    if (notifications?.length > 0) {
      return (
        <List
          itemLayout="horizontal"
          dataSource={notifications?.slice(0, 5)}
          size="small"
          renderItem={(item) => {
            const leadTime = moment(item?.createdAt).locale(language).fromNow()
            return (
              <List.Item
                onClick={() => {
                  setDetailNotification(item)
                  setVisible(true)
                }}
                className="cursor-pointer hover:bg-[#e5f0fb]"
              >
                <List.Item.Meta
                  title={
                    <>
                      <div>
                        {item?.title}{' '}
                        {item?.status === 'created' && (
                          <Badge status="processing" className="ml-2" />
                        )}
                      </div>
                      <div
                        className={`text-xs font-light text-[${
                          item?.status === 'created' ? '#30a7e8' : '#999'
                        }] `}
                      >
                        {leadTime}
                      </div>
                    </>
                  }
                />
              </List.Item>
            )
          }}
        />
      )
    }
  }
  return (
    <div>
      <Spin spinning={loading}>
        <Card
          title={
            <div className="flex items-center space-x-2">
              <span>{translate('notification')}</span>
              <span className="text-sm text-[#999]">({totalNotiUnread})</span>
            </div>
          }
          className="rounded-lg"
          extra={
            <Button
              type="link"
              onClick={() => router.push(`${NOTIFICATION}?status=created`)}
            >
              {translate('more')}
              <RightOutlined />
            </Button>
          }
        >
          {notifications?.length > 0 ? (
            renderListNotification(notifications)
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={translate('emptyNotification')}
            />
          )}
        </Card>
        <ViewDetailNotificationDrawer
          visible={visible}
          onCancel={handleCancel}
          detailNotification={detailNotification}
        />
      </Spin>
    </div>
  )
}

export default memo(NotificationHomePage)
