import { useEffect, useState } from 'react'
import { isIndia } from 'utils/commonUtils'
import { EN, VI } from 'utils/localUtils'

const useLanguage = () => {
  const [language, setLanguage] = useState<string>(isIndia ? EN : VI)

  useEffect(() => {
    if (!isIndia && localStorage.getItem('language')) {
      setLanguage(localStorage.getItem('language'))
    }
  }, [localStorage.getItem('language')])
  return language
}

export default useLanguage
