export const NOTIFICATION_ALL = 'all'
export const NOTIFICATION_UNREAD = 'created'
export const NOTIFICATION_READ = 'seen'

export const DMS_USER = 'dms_user'

export const NOTIFICATION_STATUS_MAPPING = {
  [NOTIFICATION_ALL]: 'ALL',
  [NOTIFICATION_UNREAD]: 'UNREAD',
  [NOTIFICATION_READ]: 'READ',
}
